/** @jsx jsx */
import { graphql } from "gatsby";
import { Gallery } from "gatsby-theme-gallery";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetGallery" }>;
}

const GalleryWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <Gallery
        aspectRatio={widget.galleryShape?.aspectRatio || undefined}
        displayImageMode={widget.galleryShape?.displayImageMode || "AUTO"}
        displayImageColumns={widget.galleryShape?.displayImageColumns || 5}
        displayOptionsZoomable={
          widget.galleryShape?.displayOptionsZoomable || false
        }
        pictures={widget.galleryShape?.pictures}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetGallery on WidgetGallery {
    id
    __typename
    galleryShape {
      aspectRatio
      displayImageMode
      displayImageColumns
      displayOptionsZoomable
      pictures {
        alt
        src
        linkTo
        probe {
          ratio
        }
      }
    }
  }
`;

export default memo(GalleryWidget);
